<template>
	<div class="inside-page-content">
		<div class="paper">
			<div class="page">
				<div class="top">参与攻关</div>
				<el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px" label-suffix=":" class="form">
					<el-form-item label="姓名" required prop="contactName">
						<el-input v-model="form.contactName" placeholder="请输入联系的姓名" maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label="联系方式" required prop="contactPhone">
						<el-input v-model="form.contactPhone" placeholder="请输入联系人的电话号码" maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label="公司名称" required prop="companyName">
						<el-input v-model="form.companyName" placeholder="请输入公司名称" maxlength="30"></el-input>
					</el-form-item>
					<el-form-item label="攻关优势" prop="advantage">
						<el-input
							v-model="form.advantage"
							type="textarea"
							placeholder="请输入公司的攻关优势"
							rows="4"
							maxlength="500"
							resize="none"
						></el-input>
					</el-form-item>
					<div style="display: flex;justify-content: center;">
						<el-button @click="goback" style="margin-right: 50px;width: 150px;">取消</el-button>
						<el-button type="primary" @click="onSubmit" style="width: 150px;">提交</el-button>
					</div>
				</el-form>
				<div class="top"></div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
	export default {
		name: 'deviceDetail',
		components: {
			
		},
		computed:{
			...mapState({
				user: state => state.user_info,
			}),
		},
		data() {
			return {
				form: {},
				rules: {
					companyName: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}
					],
					advantage: [{
						required: true,
						message: '请输入攻关优势',
						trigger: 'blur'
					}
					],
					contactName: [{
						required: true,
						message: '请输入联系人姓名',
						trigger: 'blur'
					}],
					contactPhone: [{
						required: true,
						message: '请输入联系人电话号码',
						trigger: 'blur'
					}],
				},
			}
		},
		created() {
			if (!this.user || !this.user.userName) {
				this.$confirm('此功能必须先登录', '提示', {
					confirmButtonText: '去登陆',
					cancelButtonText: '返回',
					showClose: false,
					closeOnClickModal: false,
					type: 'warning'
				}).then(() => {
					this.$router.push("/logon");
				}).catch(() => {
					this.$router.go(-1)          
				});
      } else {
        this.open = true
				this.form.rdId = this.$route.query.id
      }
		},
		mounted() {

		},
		methods: {
			onSubmit() {
				this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$api.share.join(this.form).then((res) => {
							this.$message({
								message: '提交成功',
								type: 'success'
							});
							this.goback()
						}).catch(() => {
							this.$router.push("/logon");
						})
          } else {
            console.log('error submit!!');
            return false;
          }
        });
			},
			goback() {
				this.$router.go(-1)
			}
		}
	}
</script>
<style scoped lang="less">
	.page {
		padding: 20px;
    min-width: 1100px;
    max-width: 1300px;
    margin: 0 auto;
		box-sizing: border-box;
		background: #fff;
		.form{
			width: 800px;
			margin: 40px auto;
		}
		.top {
			height: 60px;
			background-color: #E8EDF8;
			line-height: 60px;
			text-align: center;
		}
	}
</style>
